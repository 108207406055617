import { Injectable, ElementRef } from '@angular/core';
// import { Component, ElementRef, ViewChild, AfterViewInit} from '@angular/core';

@Injectable()
export class ToastService {

  loading: boolean;
  showModal = false;
  toastTitle: string;
  toastContent: string;
  toastType: string;
  focus: boolean;

  constructor() {
    this.focus = false;
    // const msg = this.renderer.selectRootElement('#Tmsg');
  }


  async message(content: string, type: string, title?: string) {
    this.toastType = type;
    this.toastContent = content;
    this.showModal = true;
    this.loading = false;
    switch (type) {
      case 'success': {
        this.toastTitle = title ? title : 'Success!';
        await this.delay(3000);
        this.showModal = false;
        break;
      }
      case 'error': {
        this.toastTitle = title ? title : 'Oops!';
        // this.toastTitle = 'Oops!';
        this.focus = true;
        break;
      }
      case 'warning': {
        this.toastTitle = title ? title : 'Warning!';
        // this.toastTitle = 'Warning!';
        this.focus = true;
        // angular.element('#appToastAutofocus').triggerHandler('click');
        // await this.delay(5000);
        // this.showModal = false;
        break;
      }
      case 'info': {
        // this.toastTitle = title ? title : 'Oops!';
        // this.loading = true;
        this.toastTitle = title;
        break;
      }
      case 'waiting': {
        this.loading = true;
        this.toastTitle = title;
      }
    }

  }


  dismissMessage() {
    this.showModal = false;
    this.focus = false;
    // this.loading = false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
