
export class TenantCreateRequestModel {
    tenantEmail: string;
    tenantName: string;
    adminUser: AdminUserDetails;
    address: string;
    allowPermissions: PortalPermissionsDetails[];
    configurations: ConfigurationsDetails;
}
export class AdminUserDetails {
    username: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthDate: string;
}

export class PortalPermissionsDetails {
    componentName: string;
    parentPermissions: string[];
}

export class ConfigurationsDetails {
    basePath: string;
    clientId: string;
    colors: {
        background: string;
    };
    logo: string;
}
