import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* Module */
import { NavigationModule } from './modules/navigation/navigation.module';

/* Containers */
import * as navigationContainers from './modules/navigation/containers';
import { WizardModule } from './modules/wizard/wizard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* primeng */
import {TableModule} from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

export const PrimeNg = [InputSwitchModule, DropdownModule, CalendarModule, InputMaskModule, TableModule];

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NavigationModule,
        WizardModule,
        ...PrimeNg
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        NavigationModule,
        WizardModule,
        TableModule,
        ...PrimeNg
    ],
    providers: [
    ]
})
export class SharedModule { }
