export const environment = {
  production: true,
  staging: false,
  development: false,
  local: false,
  backendUrl: 'https://xvgsxcd0c8.execute-api.ap-south-1.amazonaws.com/prod/',
  centralPortalUrl: 'https://portal.99ticks.com/',
  domain : '.99ticks.com',
  env: "prod"
};
