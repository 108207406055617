import { Injectable } from '@angular/core';
import {  AuthUser } from 'src/app/core/services/auth-services/models/auth.user.model';
import { Store } from 'src/app/shared/models/store.model';

@Injectable({
    providedIn: 'root'
})
export class AuthStore {

    private state: Store<AuthUser>;
    constructor() {
        this.state = new Store<AuthUser>({
            userId: null,
            tenantId: null,
            name: null,
            phoneNumber: null,
            permissions: [],
            role: null,
            email: null
        });
    }

    get authUser() {
        return this.state.getState();
    }
    

    get stateSnapshot() {
        return this.state.getStateSnapshot();
    }

    protected setCompleteState(authData: AuthUser) {
        this.state.setState({
            userId: authData.userId,
            tenantId: authData.tenantId,
            name:  authData.name,
            phoneNumber:  authData.phoneNumber,
            permissions: authData.permissions,
            role: authData.role,
            email: authData.email
        });
        console.log(this.state)
    }
}