import { Injectable } from '@angular/core';
import { PortalPermissionResponse } from '../models/response-models/portal-premssion-response.model';
import { ParentPermission, Portal } from '../models/view-models.ts/portal-premssion.model';

@Injectable({
    providedIn: 'root'
})
export class PortalPermissonMapper {


    mapPortalPermissionResponseToPortalPermisson(portalPermissions: PortalPermissionResponse[]) {

        return portalPermissions.map(portal => {
            const newPortal = new Portal();
            newPortal.componentName = portal.componentName;
            newPortal.parentPermissions = portal.parentPermissions.map(per => {
                const permisson = new ParentPermission();
                permisson.name = per.name;
                permisson.permissionId = per.permissionId;
                permisson.description = per.description;
                permisson.status = per.status;
                permisson.isActive = true;
                return permisson;
            });
            return newPortal;
        });
    }
}