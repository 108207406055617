import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WizardNavMenuComponent } from './containers/wizard-nav-menu/wizard-nav-menu.component';
import { WizardStepComponent } from './compoents/wizard-step/wizard-step.component';
import { WizardStepContentDirective } from './directives/wizard-step-content.directive';



@NgModule({
  declarations: [
    WizardStepComponent,
    WizardNavMenuComponent, 
    WizardStepContentDirective],
  imports: [
    CommonModule
  ],
  exports: [
    WizardNavMenuComponent,
    WizardStepComponent,
    WizardStepContentDirective
  ]
})
export class WizardModule { }
