
import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';


@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['toast-messages.component.scss'],
})
export class ToastMessagesComponent implements OnInit {
  // showModal= true;
  messages: any;

  constructor(public toast: ToastService) { }

  ngOnInit() {
    //  this.messages = this.toast.getMessages();
  }

  focusout() {
    this.toast.dismissMessage();
  }

}
