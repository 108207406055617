
<div  tabindex="-1" class="toast-top-right" id="toast-container" style="position: fixed;" [style.display]="toast.showModal ? 'block' : 'none'"  (focusout)="focusout()" [appAutofocus]="( toast.focus === true )" >
<div  class="toast " [ngClass]="{'toast-info': toast.toastType=='info',
            'toast-error':   toast.toastType=='error',
            'toast-warning':   toast.toastType=='warning',
            'toast-success':  toast.toastType=='success',
            'toast-waiting':  toast.toastType=='waiting'}"
            style="opacity: 1;">
<span class="pull-right toast-close" (click)="toast.dismissMessage()">&times;</span>
<div class="toast-sprin"  *ngIf="toast.loading">
<div class="sk-circle">
    <div class="sk-circle1 sk-child"></div>
    <div class="sk-circle2 sk-child"></div>
    <div class="sk-circle3 sk-child"></div>
    <div class="sk-circle4 sk-child"></div>
    <div class="sk-circle5 sk-child"></div>
    <div class="sk-circle6 sk-child"></div>
    <div class="sk-circle7 sk-child"></div>
    <div class="sk-circle8 sk-child"></div>
    <div class="sk-circle9 sk-child"></div>
    <div class="sk-circle10 sk-child"></div>
    <div class="sk-circle11 sk-child"></div>
    <div class="sk-circle12 sk-child"></div>
  </div>
</div>
<div class="toast-title">{{toast.toastTitle}}</div>

      <div class="ng-tns-c15-3" ng-reflect-ng-switch="false">
   <span class="toast-message">{{toast.toastContent}}</span>
      </div>             
    </div>
  </div>
