import { environment } from 'src/environments/environment';

export const ApiConfigs = {
    LoginRequest: environment.backendUrl + 'auth/users/authenticate',
    LogoutRequest: environment.backendUrl + 'umm/users/sign-out',
    TestRequest: environment.backendUrl + 'auth/user/test',
    RolesRequest: environment.backendUrl + 'umm/roles',
    // RolesRequest: 'https://run.mocky.io/v3/de200718-4e4b-442a-97f8-4a0c9650c80c',
    // RolesRequest: ' https://run.mocky.io/v3/4132e708-612d-4664-9357-7d7da0500042',

    CreateUser: environment.backendUrl + 'umm/tenants/users',
    GetUsers: environment.backendUrl + 'umm/tenants/users',
    CheckEmail: environment.backendUrl + 'umm/tenants/TENANTID/users/EMAIL',
    CreateTenant: environment.backendUrl + 'umm/tenants/create',
    GetConfigs: environment.backendUrl + 'umm/tenants/{{tenantName}}/configurations',
    ComponentPermissions: environment.backendUrl + 'umm/component-permissions',
    TenantConfigurations: environment.backendUrl + 'umm/tenants/TENANTBASEPATH/configurations',
    GetTenants : environment.backendUrl + 'umm/tenants',
    verifyUserRequest: environment.backendUrl + 'auth/users/verify',
    uploadImage : environment.backendUrl + 'umm/images/upload',
    enableUser: environment.backendUrl + 'umm/tenants/enable-user',
    disableUser: environment.backendUrl + 'umm/tenants/disable-user',
    updateUser: environment.backendUrl + 'umm/tenants/users/EMAIL',
    createStudent: environment.backendUrl + 'student/create',
    updateStudent: environment.backendUrl + 'student/EMAIL',
    deleteUser: environment.backendUrl + 'umm/tenants/users',
    csvValidation: environment.backendUrl + 'student/csv/validate',
}


export const routeParams = {
    TenantBasePath : 'TENANTBASEPATH',
    TENANTID: 'TENANTID',
    EMAIL : 'EMAIL'
}

export function replacePathValue(path, replaceString, value): string {
   
    let replacedPath = path.split(replaceString).join(value);
    return replacedPath;
}