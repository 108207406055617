import { Injectable } from '@angular/core';
import { AdminUserDetails, ConfigurationsDetails, PortalPermissionsDetails, TenantCreateRequestModel } from '../models/request-models/tenant-create-request.model';
import { PortalPermissionResponse } from '../models/response-models/portal-premssion-response.model';
import { ParentPermission, Portal } from '../models/view-models.ts/portal-premssion.model';
import { TenantWizardForm } from '../models/view-models.ts/tenant-wizard-form.model';

@Injectable({
  providedIn: 'root'
})
export class TenantMapper {


  constructor() { }

  mapUserWizardFormToCreateTenantRequest(tenantDetails: TenantWizardForm): TenantCreateRequestModel {

    const tenant = new TenantCreateRequestModel();
    tenant.tenantName = tenantDetails.tenantDetails.tenantName;
    tenant.tenantEmail = tenantDetails.tenantDetails.tenantEmail;
    tenant.address = tenantDetails.tenantDetails.tenantAddress;

    tenant.adminUser = new AdminUserDetails();
    tenant.adminUser.firstName = tenantDetails.adminDetails.firstName;
    tenant.adminUser.lastName = tenantDetails.adminDetails.lastName;
    tenant.adminUser.gender = tenantDetails.adminDetails.gender;
    tenant.adminUser.email = tenantDetails.adminDetails.email;
    tenant.adminUser.birthDate = tenantDetails.adminDetails.birthday.toLocaleDateString();
    tenant.adminUser.phone = tenantDetails.adminDetails.phoneNumber.toString().replace(/\s/g, '');
    tenant.adminUser.username = tenantDetails.adminDetails.email;

    tenant.allowPermissions = tenantDetails.portalPermissions.map(portal => {
      const newPortal = new PortalPermissionsDetails();
      newPortal.componentName = portal.componentName;
      newPortal.parentPermissions = portal.parentPermissions.filter(
        per => {
          if (per.isActive) {
            return per;
          }
        }).map(per => {
          return per.permissionId;
        });
      return newPortal;
    });

    tenant.configurations = new ConfigurationsDetails();
    tenant.configurations.basePath = tenantDetails.tenantDetails.basePath;
    tenant.configurations.clientId = null;
    tenant.configurations.colors = { background: '#A5FAFF' };
    tenant.configurations.logo = tenantDetails.tenantDetails.logoUrl;
    // need to remove hard codes
    return tenant;

  }


}
