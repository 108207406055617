import { Inject, Injectable } from '@angular/core';
import { AuthUser } from 'src/app/core/services/auth-services/models/auth.user.model';
import { CookieServices } from '../cookie-service/cookieService';
import { AuthAPIHandler } from '../../api/handlers/auth.api.handler';
import { ResponseCodes } from 'src/app/shared/enums/response-codes.enum';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { AuthStore } from './stores/auth.store.service';
import { PermissionService } from '../permission-service/permission.service';

@Injectable({
    providedIn: 'root'
})

export class AuthService extends  AuthStore{

    constructor(private permissionService: PermissionService,
                private cookieService: CookieServices,
                private authAPIHandler: AuthAPIHandler,
                @Inject(DOCUMENT) private document: Document) {
        super();
    }

    authenticate(): Promise<any> {
        console.log('authenticate');
        return new Promise((resolve, reject) => {
                return this.authAPIHandler.verifyUser()
                .then((user: AuthUser) => {
                    console.log(user);
                    this.setAuthUser(user);
                    this.permissionService.setPermissionsKeys(user.permissions);
                    if(!this.permissionService.HasApplicationAccess()) {
                        reject(null);
                    }else{
                        resolve(user);
                    }
                }).catch(err => {
                    console.log(err);
                    reject(null);
                });
        });
    }

    get accessToken() {
        return this.cookieService.getAccessToken;
    }

    get refreshToken() {
        return this.cookieService.getRefreshToken;
    }

    isLoggedIn() {
       return this.cookieService.isAuthenticated();
    }

    setAuthUser(auth: AuthUser) {
        return super.setCompleteState(auth);
    }
    getAuthUser() {
        return super.authUser;
    }

    logout() {
        return  this.authAPIHandler.requestToLogout().then(status => {
              if (status === ResponseCodes.SignoutCompleted) {
                  this.cookieService.destroyTokens();
                  this.document.location.href = environment.centralPortalUrl;
                  return true;
              } else {
                  return false;
              }
  
          });
      }
}