import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { HttpStatus } from 'src/app/shared/enums/http-status.enum';
import { TenantCofigurationsResponse } from 'src/app/shared/models/tenant-configuration.model';
import { ToastService } from 'src/app/shared/toast-messages/toast.service';
import { environment } from 'src/environments/environment';
import { TenantAPIHandler } from '../../api/handlers/tenant.api.handler';
import { CookieServices } from '../cookie-service/cookieService';
import { PortalPermissonMapper } from './mappers/portal-permission.mapper.service';
import { TenantMapper } from './mappers/tenant.mapper.service';
import { ImageData } from './models/request-models/image.model';
import { Tenant } from './models/tenant.model';
import { TenantWizardForm } from './models/view-models.ts/tenant-wizard-form.model';
import { TenantStore } from './stores/tenant.store';


@Injectable({
    providedIn: 'root'
})
export class TenantService extends TenantStore {

    constructor(private tenantAPIHandler: TenantAPIHandler,
        private tenantMapper: TenantMapper,
        private portalPermissonMapper: PortalPermissonMapper,
        private toast: ToastService,
        private cookiesService: CookieServices
    ) {
        super();
    }

    getTenantConfigurations(tenantName: string): Promise<any> {

        return new Promise((resolve, reject) => {
            return this.tenantAPIHandler.getTenantConfigurations(tenantName).then((data: TenantCofigurationsResponse) => {
                let tenant = new Tenant();
                console.log(data);
                tenant.basePath = data.basePath;
                tenant.logo = data.logo;
                tenant.favIcon = data.favIcon;
                tenant.colors = data.colors;
                tenant.clientId = data.clientId;
                tenant.tenantId = data.tenantId;
                this.cookiesService.setBasePath(data.basePath);
                super.setCompleteState(tenant);
                resolve(tenant);
            }).catch(err => {
                console.log(err);
                reject(null);
            });
        });
    }

    ValidateTenant(tenantName: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            return this.getTenantConfigurations(tenantName).then(data => {
                console.log(data);
                resolve(true);
            }).catch(err => {
                window.location.href = environment.centralPortalUrl;
                reject(false);
            });
        });
    }
    getBasePath() {
        return super.tenantState;
    }

    setTenant(tenant: Tenant) {
        return super.setCompleteState(tenant);
    }
    getTenant() {
        return super.tenantState;
    }

    getTenantList() {
        this.toast.message('Getting tenants', 'waiting');
        return this.tenantAPIHandler.fetchTenantsList().then(res => {
            console.log(res)
            this.toast.dismissMessage();
            return res;
        });
    }

    createTenant(tenantDetails: TenantWizardForm) {
        this.toast.message('Creating tenant', 'waiting');
        const mapedRequestObj = this.tenantMapper.mapUserWizardFormToCreateTenantRequest(tenantDetails);
        return this.tenantAPIHandler.createTenant(mapedRequestObj).then(res => {
            this.toast.message('Tenant created', 'success');
            return res;
        }).catch(err => {
            this.toast.message('Tenant creatation failed', 'success');
            return err;
        });;
    }

    getTenantPermissionList() {
        return this.tenantAPIHandler.getTenantPermsion().then(res => {
            const mapedRequestObj = this.portalPermissonMapper.mapPortalPermissionResponseToPortalPermisson(res);
            return mapedRequestObj;
        });
    }

    uploadImage(image: ImageData) {
        this.toast.message('Uploading image', 'waiting');
        return this.tenantAPIHandler.uploadImage(image).then(url => {
            this.toast.message('Image Uploaded', 'success');
            return url;
        }).catch(err => {
            this.toast.message('Image Upload Failed', 'error');
            return err;
        });
    }


}