import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/gaurds/auth.guard';

const routes: Routes = [
    {
        path: ':tenant',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/user-managment/user-managment.module').then(m => m.UserManagmentModule),
    },
    {
        path: ':tenant/user-managment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/user-managment/user-managment.module').then(m => m.UserManagmentModule),
    },
    {
        path: ':tenant/tenant-managment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./features/tenant-managment/tenant-managment.module').then(m => m.TenantManagmentModule),
    },
    {
        path: '',
        canActivate: [AuthGuard],
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }