export class Portal {
    componentName: string;
    parentPermissions: ParentPermission[];
}

export class ParentPermission {
    permissionId: string;
    name: string;
    description: string;
    status: boolean;
    isActive: boolean;
}