import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { ApiConfigs, replacePathValue, routeParams } from 'src/app/shared/constants/api-configs';
import { HttpStatus } from 'src/app/shared/enums/http-status.enum';
import { Auth } from 'src/app/shared/models/auth.model';
import { BaseResponse } from 'src/app/shared/models/base-response.model';
import { AuthUser } from '../../services/auth-services/models/auth.user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthAPIHandler {

    constructor(private apiService: ApiService) { }

    // authenticate(auth: Auth): Promise<Auth> {
    //     return new Promise((resolve, reject) => {
            
    //         // set routes paths
    //         const path = ApiConfigs.LoginRequest;
    //         return this.apiService.post(path,auth).subscribe(
    //             (res: HttpResponse<BaseResponse<Auth>>) => {
    //                 if (res.status === HttpStatus.Success) {
    //                     resolve(res.body.data);
    //                 }else{
    //                         reject(null);
    //                 }
    //             },(res: HttpErrorResponse) => {
    //                 console.log(res)
    //                 const err: BaseResponse<null> = res.error;
    //                 if (res.status === HttpStatus.BadRequest) {
    //                         reject(null);
    //                 }
    //         });
    //     });
    // }

    verifyUser(): Promise<AuthUser> {
        console.log('verifyUser');
        return new Promise((resolve, reject) => {
            const path = ApiConfigs.verifyUserRequest;
            return this.apiService.get(path).subscribe(
                (res: HttpResponse<BaseResponse<AuthUser>>) => {
                    if (res.status === HttpStatus.Success) {
                        const authUser = new AuthUser();
                        authUser.permissions = res.body.data['custom:permissions'];
                        authUser.userId = res.body.data['custom:userId'];
                        authUser.role = res.body.data['custom:role'];
                        authUser.name = res.body.data['name'];
                        authUser.phoneNumber = res.body.data['phoneNumber'];
                        authUser.email = res.body.data['email'];

                        resolve(authUser);
                    } else {
                        reject(null);
                    }
                }, (res: HttpErrorResponse) => {
                    console.log(res)
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                        reject(null);
                    }
                });
        });
    }

    requestToLogout(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(ApiConfigs.LogoutRequest, null).subscribe(
                (res: HttpResponse<BaseResponse<null>>) => {
                    if (res.status === HttpStatus.Success) {
                        resolve(res.body.messageKey);
                    }
                },
                (res: HttpErrorResponse) => {
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                        reject(res.error);
                    }
                });
        });
    }
}