import { Directive, AfterViewInit, ElementRef, OnChanges, SimpleChanges, OnDestroy, AfterContentInit} from '@angular/core';


@Directive({
  selector: '[appToastAutofocus] ,[appAutofocus]',
  // tslint:disable-next-line:use-input-property-decorator
  inputs: [
    'shouldFocusElement: appAutofocus',
    'timerDelay: autofocusDelay']
})

export class ToastAutofocusDirective implements AfterViewInit, AfterContentInit, OnChanges, OnDestroy {

  public shouldFocusElement: any;
  public timerDelay: any;

  private elementRef: ElementRef;
  private timer: any;

  // I initialize the autofocus directive.
  constructor(elementRef: ElementRef) {

    this.elementRef = elementRef;

    this.shouldFocusElement = '';
    this.timer = null;
    this.timerDelay = 10;

  }

  ngAfterContentInit(): void {
    // throw new Error("Method not implemented.");
    if (this.shouldFocusElement === '') {

      this.startFocusWorkflow();

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If the timer delay is being passed-in as a string (ie, someone is using
    // attribute-input syntax, not property-input syntax), let's coalesce the
    // attribute to a numeric value so that our type-annotations are consistent.
    if (changes.timerDelay && (typeof (this.timerDelay) !== 'number')) {

      // If the coalesce fails, just fall-back to a sane value.
      if (isNaN(this.timerDelay = +this.timerDelay)) {

        this.timerDelay = 10;

      }

    }

    // If the focus input is being data-driven, then we either need to start the
    // focus workflow if focus is required; or, clear any existing workflow if focus
    // is no longer required (so that we don't steal focus from another element).
    if (changes.shouldFocusElement) {

      (this.shouldFocusElement)
        ? this.startFocusWorkflow()
        : this.stopFocusWorkflow()
        ;

    }

  }
  ngOnDestroy(): void {
    this.stopFocusWorkflow();
  }


  private startFocusWorkflow(): void {

    // If there is already a timer running for this element, just let it play out -
    // resetting it at this point will only push-out the time at which the focus is
    // applied to the element.
    if (this.timer) {

      return;

    }

    this.timer = setTimeout(
      (): void => {

        this.timer = null;
        this.elementRef.nativeElement.focus();

      },
      this.timerDelay
    );

  }


  // I stop the timer-based workflow, preventing focus from taking place.
  private stopFocusWorkflow(): void {

    clearTimeout(this.timer);
    this.timer = null;

  }
  ngAfterViewInit() {
    // this.el.nativeElement.focus();
  }
}
