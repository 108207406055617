import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastMessagesComponent } from './shared/toast-messages/toast-messages.component';
import { ToastAutofocusDirective } from './shared/toast-messages/toast-autofocus.directive';
import { ToastService } from './shared/toast-messages/toast.service';
import { JwtInterceptor } from './core/api/interceptor/api.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ToastAutofocusDirective,
    ToastMessagesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },ToastService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
