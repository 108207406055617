import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { ApiConfigs, replacePathValue, routeParams } from 'src/app/shared/constants/api-configs';
import { HttpStatus } from 'src/app/shared/enums/http-status.enum';
import { ResponseCodes } from 'src/app/shared/enums/response-codes.enum';
import { ResponseStatus } from 'src/app/shared/enums/response-status.enum';
import { BaseResponse } from 'src/app/shared/models/base-response.model';
import { TenantCofigurationsResponse } from 'src/app/shared/models/tenant-configuration.model';
import { ImageData } from '../../services/teant-services/models/request-models/image.model';
import { TenantCreateRequestModel } from '../../services/teant-services/models/request-models/tenant-create-request.model';
import { PortalPermissionResponse } from '../../services/teant-services/models/response-models/portal-premssion-response.model';
import { TenantResponseModel } from '../../services/teant-services/models/response-models/tenant-response-model';

@Injectable({
    providedIn: 'root'
})
export class TenantAPIHandler {

    constructor(private apiService: ApiService) { }

    getTenantConfigurations(tenantBasePath: string): Promise<TenantCofigurationsResponse> {

        return new Promise((resolve, reject) => {
            // set routes paths
            const path = replacePathValue(ApiConfigs.TenantConfigurations,routeParams.TenantBasePath,tenantBasePath);
            //const path = replacePathValue(ApiConfigs.TenantConfigurations,routeParams.TenantBasePath,tenantBasePath);

            console.log('request send');
            return this.apiService.get(path).subscribe(
                (res: HttpResponse<BaseResponse<TenantCofigurationsResponse>>) => {
                    if (res.status === HttpStatus.Success) {
                        if(res.body.messageKey == ResponseCodes.TenantNotFound){
                            reject(null);
                        }else{
                            resolve(res.body.data);
                        }
                    }
                },
                (res: HttpErrorResponse) => {
                    console.log(res)
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                            reject(null);
                    }
                });
            });
    }

    createTenant(tenantDetails: TenantCreateRequestModel): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(ApiConfigs.CreateTenant, tenantDetails ).subscribe(
                (res: HttpResponse<BaseResponse<null>>) => {
                    if (res.body.status === ResponseStatus.Success) {
                        resolve(res.body.status);
                    }
                },
                (res: HttpErrorResponse) => {
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                        if (err.messageKey === ResponseCodes.InvalidPasswordOrUsername) {
                            resolve(res.error);
                        }
                    }
                });
        });
    }

    getTenantPermsion(): Promise<PortalPermissionResponse[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get(ApiConfigs.ComponentPermissions).subscribe(
                (res: HttpResponse<BaseResponse<PortalPermissionResponse[]>>) => {
                    if (res.body.status === ResponseStatus.Success) {
                        console.log(res)
                        resolve(res.body.data);
                    }
                },
                (res: HttpErrorResponse) => {
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                        if (err.messageKey === ResponseCodes.InvalidPasswordOrUsername) {
                            resolve(res.error);
                        }
                    }
                });
        });
    }
    uploadImage(image :ImageData): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.post(ApiConfigs.uploadImage,{image : image}).subscribe(
                (res: HttpResponse<BaseResponse<any>>) => {
                    if (res.body.status === ResponseStatus.Success) {
                        console.log(res)
                        resolve(res.body.data.url);
                    }
                },
                (res: HttpErrorResponse) => {
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                            reject(res.error);
                    }
                });
        });
    }

    fetchTenantsList(): Promise<TenantResponseModel[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get(ApiConfigs.GetTenants).subscribe(
                (res: HttpResponse<BaseResponse<any>>) => {
                    if (res.body.status === ResponseStatus.Success) {
                        console.log(res)
                        resolve(res.body.data);
                    }
                },
                (res: HttpErrorResponse) => {
                    const err: BaseResponse<null> = res.error;
                    if (res.status === HttpStatus.BadRequest) {
                            reject(res.error);
                    }
                });
        });
    }
}