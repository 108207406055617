<div *ngIf="loading" class="background">
    <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
    </div>

</div>
<div *ngIf="(loading == false && !isValidTenant)" class="background">
    <h1>99tick landing page</h1>
</div>
<router-outlet></router-outlet>
<app-toast-messages></app-toast-messages>
